import React from 'react';

// Rouge
export const PRIMARY_COLOR = {
    '50': '#fde7e8',
    '100': '#fac2c5',
    '200': '#f79a9f',
    '300': '#f37279',
    '400': '#f1535c',
    '500': '#ee353f',
    '600': '#ec3039',
    '700': '#e92831',
    '800': '#e72229',
    '900': '#e2161b',
    'A100': '#ffffff',
    'A200': '#ffe0e0',
    'A400': '#ffadae',
    'A700': '#ff9395',
};

/*
// Bleu
export const SECONDARY_COLOR = {
    '50': '#e1e2e5',
    '100': '#b5b6bf',
    '200': '#848694',
    '300': '#535669',
    '400': '#2e3149',
    '500': '#090d29',
    '600': '#080b24',
    '700': '#06091f',
    '800': '#050719',
    '900': '#02030f',
    'A100': '#5252ff',
    'A200': '#1f1fff',
    'A400': '#0000eb',
    'A700': '#0000d1',    
};
*/


// Jaune
export const SECONDARY_COLOR = {
    '50': '#fdfceb',
    '100': '#fbf8cc',
    '200': '#f8f3ab',
    '300': '#f5ee89',
    '400': '#f3ea6f',
    '500': '#f1e656',
    '600': '#efe34f',
    '700': '#eddf45',
    '800': '#ebdb3c',
    '900': '#e7d52b',
    'A100': '#ffffff',
    //'A200': '#fffef3',
    'A200': '#eddf45',    
    //'A400': '#fff9c0',
    'A400': '#eddf45',
    'A700': '#fff6a7',
};



export const GOOGLE_RECAPTCHA_SITE_KEY = __GOOGLE_RECAPTCHA_SITE_KEY__;
export const GOOGLE_TAG_MANAGER_ARGS = {
    gtmId: __GOOGLE_TAG_MANAGER_ID__,
};
export const NEWSLETTER_SERVER_URL = __NEWSLETTER_SERVER_URL__;



/////////////////////////////////////////////////////////
//                                                     //
//          Configuration OpenStreetMap (OSM)          //
//                                                     //
/////////////////////////////////////////////////////////

export const OSM_MAP_NAME = "OpenStreetMap_France";

export const OSM_MARKER_ICON_RETINA_URL = "/static/img/leaflet/dist/images/marker-icon-2x.png";
export const OSM_MARKER_ICON_URL = "/static/img/leaflet/dist/images/marker-icon.png";
export const OSM_MARKER_SHADOW_URL = "/static/img/leaflet/dist/images/marker-shadow.png";



//////////////////////////////////////////////////////
//                                                  //
//          Bouton d'action flottant (FAB)          //
//                                                  //
//////////////////////////////////////////////////////

// Icones utilisées
import MenuBookIcon from '@material-ui/icons/MenuBook';
import SearchIcon from '@material-ui/icons/Search';

// Liste des actions (= boutons) contenues dans le "FAB"
export const FAB_ACTIONS = [
    { icon: <MenuBookIcon color="primary" />, name: 'Ressource documentaire', url: "/ressource-documentaire/" },
    { icon: <SearchIcon color="primary" />, name: 'Rechercher', url: "/rechercher/" },
];



/////////////////////////////////////////////////////
//                                                 //
//          Icones utilisées dans le menu          //
//                                                 //
/////////////////////////////////////////////////////


export const MENU_ICON_MAPPING = {
}
